.navbar {
    background-image: radial-gradient(rgba(232, 214, 213, 0.53), rgba(243, 238, 227, 1) 50%, rgba(217, 233, 205, 0.35));
    color: var(--lightest-slate);
    font-family: 'NTR';
    font-weight: bold;
  }

  .navbar-light .navbar-brand  {
    color: var(--lightest-slate) !important;
  }

  .navbar-light .navbar-nav .nav-link {
    color: var(--lightest-slate) !important;
  }

  .navbar-light .navbar-nav .nav-link:hover {
    color: var(--green-dark) !important;
  }

  @media only screen and (max-width: 600px) {
    .navbar-light .navbar-brand  {
        display: none;
    }
  
    .navbar-light .navbar-nav.me-auto .nav-link {
        display: none;
      }
  }