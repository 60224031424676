#projects {
  margin: auto;
  padding-top: 100px;
  max-width: 1000px;
  min-height: 50vh;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  grid-gap: 10px;
}

@media (max-width: 1080px) {
  .projects-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

.projects-card {
  position: relative;
  cursor: default;
  display: flex;
  flex-direction: column;
  width: 100%; 
  height: auto; 
  overflow: hidden; 
  padding: 2rem 1.75rem;
  background-color: var(--slate);
  border-radius: 16px;
  padding-top: 55%
}


.project-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; 
  z-index: -1;
}

.projects-card:hover {
  transform: translateY(-7px);
  background-color: var(--green-dark);
}

.card-header {
  margin-top: -20px;
  display: flex;
  padding: 1.25em 0 !important;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: none !important;
  background-color: transparent !important;
}

.folder-icon {
  color: var(--green-bright);
  margin-top: -250px;
}

.github-icon {
  font-size: 1rem; 
  color: var(  --lightest-navy);
  margin-top: -250px;
}

.github-icon:hover {
  color: var(--lightest-navy);
}

.open-icon {
  margin-left: 10px;
}

.card-title {
  font-family: "NTR", sans-serif;
  color: var(--lightest-slate);
  font-size: 24px;
  font-weight: bold;
}

.card-desc {
  font-family: "NTR", sans-serif;
  color: var(--slate);
  font-size: 18px;
  padding-bottom: 40px;
}

.card-tech {
  font-family: "NTR", sans-serif;
  color: var(--slate);
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  #projects {
    height: unset;
    padding-left: unset;
    padding-right: unset;
  }
  .carousel {
    display: none;
  }
}
